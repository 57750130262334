import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
import Footer from "../Footer";
let source = axios.CancelToken.source();

class Finance extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            filtered:[],
            objsload: false,
            activetable:[],
            tables:[],
            orderpaymentdata:[],
            id:-1,
            txtsearch:'',
            selectedsearch_table:"-1",
            selectedsearch_status:"-1",

            //modal
            cushow:false,
            totalprice:0,
            table_id:-1,
        }
    }
    componentDidMount() {
        this.fetcheddata()
        setInterval(() => {
            this.fetcheddata(true)
        }, 10000)
    }
    fetcheddata = (isauto=false) => {
        if(!isauto){
            this.setState({
                objs: [],
                activetable:[],
                objsload: false,
                id:-1,
                cushow:false,
                totalprice:0,
                table_id:-1,
                selectedsearch_table:"-1",
                selectedsearch_status:"-1",
            })
        }
        let baseurl = myconfig.getordersforfinance;
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered: res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

        baseurl = myconfig.gettableforpayment;
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    activetable: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

        baseurl = myconfig.gettables;
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    tables: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    activerequesttable=()=> {
        let retdata = this.state.activetable.map(value => {
            return (<>
                <tr>
                    <td className="text-center"><a href="" onClick={(event => this.showcu(event,value.id))}>{value.table_name}</a></td>
                </tr>
            </>)
        })
        return <>{retdata}</>
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            retdata = this.state.filtered.map(value => {
                let status="Finished";
                if(value.status=="Queed")
                    status="Started";
                console.log(value)
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td>{value.name_am}</td>
                    <td>{value.name_en}</td>
                    <td>{value.price}</td>
                    <td>{value.type}</td>
                    <td>{value.table_name}</td>
                    <td>{value.order_by}</td>
                    <td>{value.quantity}</td>
                    <td>{value.remark}</td>
                    <td>{value.status}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }


    modaldata=()=> {
        let retdata = null;
        console.log(this.state.orderpaymentdata);
        let count = 0;
        retdata = this.state.orderpaymentdata.map(value => {
            console.log(value)
            count = count + 1
            return (<tr>
                <td>{count}</td>
                <td>{value.name_am}</td>
                <td>{value.name_en}</td>
                <td>{value.type}</td>
                <td>{value.table_name}</td>
                <td>{value.price}</td>
                <td>{value.quantity}</td>
                <td>{value.totalprice}</td>
            </tr>)
        })
        return (<>{retdata}</>)
    }
    showcu = (event,id=-1) => {
        event.preventDefault()
        let baseurl = myconfig.getpaymentdetbytable;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            table_id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    cushow: true,
                    orderpaymentdata: res.data.datas,
                    totalprice:res.data.totals,
                    table_id:id,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.orderpayment;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            table_id: this.state.table_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>Payment Managment</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Total Payment</h3>
                                </div>
                                <div className="card-body">
                                    <h6>{this.state.totalprice} ETB</h6>
                                    <div className="form-group">
                                        <button  className="form-control btn btn-outline-primary btn-block" onClick={this.submitcu}>
                                            Paid
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Today payment history</h3>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-head text-nowrap">
                                        <thead>
                                        <tr>
                                            <th width="5.5%">#</th>
                                            <th>Menu Amharic</th>
                                            <th>Menu English</th>
                                            <th>Type</th>
                                            <th>Table Name</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Total Price</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.modaldata()}
                                        </tbody>
                                    </table>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    combotable=()=> {
        let retdata = this.state.tables.map(value => {
            return (<>
                <option value={value.id}>{value.table_name}</option>
            </>)
        })
        return <>{retdata}</>
    }
    searchbytext = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name_en.toUpperCase().includes(value.toUpperCase()) ||
                    v.name_am.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            filtered: temp
        })
    }
    searchbytable = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
        let temp = this.state.objs.datas;
        if (value != "-1") {
            temp = temp.filter(v => {
                if (v.table_id == value)
                    return value;
            })
        }
        this.setState({
            filtered: temp
        })
    }
    searchbystatus = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
        let temp = this.state.objs.datas;
        if (value != "-1") {
            temp = temp.filter(v => {
                if (v.status == value)
                    return value;
            })
        }
        this.setState({
            filtered: temp
        })
    }
    render() {
        return (
            <>
                <Nav type="finance"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Finance Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Finance</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Active Payment</h3>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>Payment Request Table</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.activerequesttable()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Today payment history</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right" value={this.state.txtsearch} onChange={this.searchbytext} placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <select name="selectedsearch_table" className="form-control float-right" value={this.state.selectedsearch_table} onChange={this.searchbytable}>
                                                        <option value="-1">--Select Table--</option>
                                                        {this.combotable()}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <select name="selectedsearch_status" className="form-control float-right" value={this.state.selectedsearch_status} onChange={this.searchbystatus}>
                                                        <option value="-1">--Select Status--</option>
                                                        <option value="Finance">Finance</option>
                                                        <option value="Paid">Paid</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th>Menu Amharic</th>
                                                    <th>Menu English</th>
                                                    <th>Price</th>
                                                    <th>Type</th>
                                                    <th>Table Name</th>
                                                    <th>Order By</th>
                                                    <th>Quantity</th>
                                                    <th>Remark</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer/>
                {this.modalcu()}
            </>
        )
    }
}

export default Finance;
