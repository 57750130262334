import {Component} from 'react';
import axios from "axios"
import Nav from "../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
let source = axios.CancelToken.source();

class RFinance extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objsload: false,
            id: -1,
            st: '',
            ed: '',
        }
    }
    componentDidMount() {
    }
    fetcheddata = () => {
        let baseurl = myconfig.rgetorders;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
            st:this.state.st,
            ed:this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    obj: res.data,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                let expire="No"
                if(value.stock_wastage_isExpire==1)
                    expire="Yes"
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.type}</td>
                    <td>{value.table_name}</td>
                    <td>{value.price}</td>
                    <td>{value.quantity}</td>
                    <td>{value.totalprice}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btngetreport = (event) => {
        event.preventDefault();
        this.fetcheddata()
    }

    render() {
        return (
            <>
                <Nav type="rfinance"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Order Finance Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report Finance Order</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Report Criteria</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <label>Start Date</label>
                                                        <input  className="form-control" type="date" name="st" onChange={this.inputmgt} value={this.state.st}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <label>End Date</label>
                                                        <input  className="form-control" type="date" name="ed" onChange={this.inputmgt} value={this.state.ed}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3">
                                                    <div className="form-group">
                                                        <button  className="btn btn-outline-primary form-control" onClick={this.btngetreport}> Get Report</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Report Data</h3>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th>Menu Name (EN) </th>
                                                    <th>Menu Name (AMH) </th>
                                                    <th>Type</th>
                                                    <th>Table Name</th>
                                                    <th>Price (ETB)</th>
                                                    <th>Quantity</th>
                                                    <th>Total Price (ETB)</th>
                                                </tr>
                                                </thead>
                                                <tbody>{this.tabledata()}</tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default RFinance;
