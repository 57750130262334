import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class Login extends Component {
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
        }
    }
    componentDidMount() {
        this.checkauth()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    btnlogin = (event) => {
        event.preventDefault();
        if (this.state.username == "") {
            Swal.fire({
                title: 'Error!',
                text: 'Error Enter user name',
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.password == "") {
            Swal.fire({
                title: 'Error!',
                text: "Enter password",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let baseurl = myconfig.login
            axios.post(baseurl, {
                'username': this.state.username,
                'password': this.state.password,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'User account is not correct.',
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let redirect = myconfig.redirect;
                    window.localStorage.setItem("mycafe_sid", res.data.sid);
                    window.localStorage.setItem("mycafe_username", res.data.username);
                    window.localStorage.setItem("mycafe_name", res.data.name);
                    var win = window.open(redirect, "_self");
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    inputmgt = (event) => {
        if (!this.state.excuting) {
            event.preventDefault();
            var name = event.target.name;
            var value = event.target.value;
            this.setState({
                [name]: value,
            })
        }
    }
    checkauth=()=> {
        let sid = window.localStorage.getItem("mycafe_sid");
        if (sid == null) {
            //since your are at login page do nothing.
        } else {
            let baseurl = myconfig.checksession
            axios.post(baseurl, {
                'sid': sid,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("mycafe_sid", null);
                    window.localStorage.setItem("mycafe_username", null);
                    window.localStorage.setItem("mycafe_name", null);
                } else {
                    let redirect = myconfig.redirect;
                    var win = window.open(redirect, "_self");
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    render() {
        return (
            <>
                <div className="login-page">
                    <div className="login-box">
                        <div className="login-logo">
                            <a href={myconfig.urlwelcome}><b>Guansa</b>Ma'ed</a>
                        </div>
                        <div className="card">
                            <div className="card-body login-card-body">
                                <p className="login-box-msg">Sign in to start your session</p>
                                <form onSubmit={this.btnlogin} method="post">
                                    <div className="input-group mb-3">
                                        <input type="text" name="username" className="form-control" placeholder="Username" value={this.state.username} onChange={this.inputmgt} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group mb-3">
                                        <input type="password" name="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.inputmgt} />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <div className="icheck-primary">
                                                <input type="checkbox" id="remember" />
                                                <label htmlFor="remember">
                                                    Remember Me
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <button type="submit" onClick={this.btnlogin} className="btn btn-primary btn-block">Sign In</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="betternet-wrapper" />
                </div>
            </>
        )
    }
}

export default Login;
