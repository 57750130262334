import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class UserAccess2 extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            objs: [],
            objload: false,
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    submitaccess = (event) => {
        event.preventDefault()
        let baseurl = myconfig.updateuseraccess;
        axios.post(baseurl, {
            id: this.state.id,
            datas: this.state.obj.datas,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetcheddata = () => {
        let baseurl = myconfig.getuseraccess;
        let params = (new URL(document.location)).searchParams;
        let id = params.get("id")
        //
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            }
            else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else {
                if(!res.data.isfound){
                    window.open(myconfig.useraccess1, "_self");
                }
                else if(res.data.isadmin){
                    Swal.fire({
                        title: 'Information!',
                        text: res.data.msg,
                        icon: 'info',
                        confirmButtonText: 'Okay'
                    }).then(value => {
                        window.open(myconfig.useraccess1, "_self");
                    })
                }
                else {
                    this.setState({
                        obj: res.data,
                        objload: true,
                        id:id,
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgtchk = (event) => {
        var name = event.target.name;
        var v = event.target.checked
        let temp=this.state.obj;
        let temp2 = temp.datas.map(value => {
            if (value.object_name + "create" == name) {
                value.create = v;
            }
            else if (value.object_name + "update" == name) {
                value.update = v;
            }
            else if (value.object_name + "read" == name) {
                value.read = v;
            }
            else if (value.object_name + "delete" == name) {
                value.delete = v;
            }
            else if (value.object_name + "all" == name) {
                value.all = v;
            }
            else if (value.object_name + "none" == name) {
                value.none = v;
            }
            return value;
        })
        temp.datas=temp2;
        this.setState({
            objs: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                count = count + 1
                return (<tr>
                    <td>{count}</td>
                    <td>
                        {value.object_name}
                    </td>
                    <td>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={value.create}
                                   name={value.object_name+"create"} onChange={this.inputmgtchk}/>
                            <label className="form-check-label">Create</label>
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={value.update}
                                   name={value.object_name+"update"} onChange={this.inputmgtchk}/>
                            <label className="form-check-label">Update</label>
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={value.read}
                                   name={value.object_name+"read"} onChange={this.inputmgtchk}/>
                            <label className="form-check-label">Read</label>
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={value.delete}
                                   name={value.object_name+"delete"} onChange={this.inputmgtchk}/>
                            <label className="form-check-label">Delete</label>
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={value.all}
                                   name={value.object_name+"all"} onChange={this.inputmgtchk}/>
                            <label className="form-check-label">All</label>
                        </div>
                    </td>
                    <td>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" checked={value.none}
                                   name={value.object_name+"none"} onChange={this.inputmgtchk}/>
                            <label className="form-check-label">None</label>
                        </div>
                    </td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    render() {
        return (
            <>
                <Nav type="useraccess1"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">User Access's Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.useraccess1}>User Access-1</a>
                                        </li>
                                        <li className="breadcrumb-item active">User Access-2</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block" onClick={this.submitaccess}>Update Access</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Object Name</th>
                                                    <th>Create</th>
                                                    <th>Update</th>
                                                    <th>Read</th>
                                                    <th>Delete</th>
                                                    <th>All</th>
                                                    <th>None</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default UserAccess2;
