export const myconfig =
    {
            /*          "siunitcreate": "http://localhost/mycafe2api/public/api/siunit/create",
                      "siunitupdate": "http://localhost/mycafe2api/public/api/siunit/update",
                      "siunitgetsiunits": "http://localhost/mycafe2api/public/api/siunit/getsiunits",
                      "siunitgetsiunit": "http://localhost/mycafe2api/public/api/siunit/getsiunit",

                      "departmentcreate": "http://localhost/mycafe2api/public/api/department/create",
                      "departmentupdate": "http://localhost/mycafe2api/public/api/department/update",
                      "departmentgetdepartments": "http://localhost/mycafe2api/public/api/department/getdepartments",
                      "departmentgetdepartment": "http://localhost/mycafe2api/public/api/department/getdepartment",

                      "employeecreate": "http://localhost/mycafe2api/public/api/employee/create",
                      "employeeupdate": "http://localhost/mycafe2api/public/api/employee/update",
                      "employeegetemployees": "http://localhost/mycafe2api/public/api/employee/getemployees",
                      "employeegetemployee": "http://localhost/mycafe2api/public/api/employee/getemployee",

                      "stockitemcreate": "http://localhost/mycafe2api/public/api/stockitem/create",
                      "stockitemupdate": "http://localhost/mycafe2api/public/api/stockitem/update",
                      "stockitemgetstockitems": "http://localhost/mycafe2api/public/api/stockitem/getstockitems",
                      "stockitemgetstockitem": "http://localhost/mycafe2api/public/api/stockitem/getstockitem",

                      "stockincreate": "http://localhost/mycafe2api/public/api/stockin/create",
                      "stockinupdate": "http://localhost/mycafe2api/public/api/stockin/update",
                      "stockingetstockins": "http://localhost/mycafe2api/public/api/stockin/getstockins",
                      "stockingetstockin": "http://localhost/mycafe2api/public/api/stockin/getstockin",

                      "stockoutcreate": "http://localhost/mycafe2api/public/api/stockout/create",
                      "stockoutupdate": "http://localhost/mycafe2api/public/api/stockout/update",
                      "stockoutgetstockouts": "http://localhost/mycafe2api/public/api/stockout/getstockouts",
                      "stockoutgetstockout": "http://localhost/mycafe2api/public/api/stockout/getstockout",

                      "stockwastagecreate": "http://localhost/mycafe2api/public/api/stockwastage/create",
                      "stockwastageupdate": "http://localhost/mycafe2api/public/api/stockwastage/update",
                      "getstockwastages": "http://localhost/mycafe2api/public/api/stockwastage/getstockwastages",
                      "getstockwastage": "http://localhost/mycafe2api/public/api/stockwastage/getstockwastage",

                      "reportgetstockin": "http://localhost/mycafe2api/public/api/report/getstockin",
                      "reportgetstockout": "http://localhost/mycafe2api/public/api/report/getstockout",
                      "reportgetstockwastage": "http://localhost/mycafe2api/public/api/report/getstockwastage",
                      "reportgetstockitem": "http://localhost/mycafe2api/public/api/report/getstockitem",
                      "rgetorders": "http://localhost/mycafe2api/public/api/report/rgetorders",

                      "createuser": "http://localhost/mycafe2api/public/api/user/createuser",
                      "updateuser": "http://localhost/mycafe2api/public/api/user/updateuser",
                      "changepwduser": "http://localhost/mycafe2api/public/api/user/changepwduser",
                      "getusers": "http://localhost/mycafe2api/public/api/user/getusers",
                      "getuser": "http://localhost/mycafe2api/public/api/user/getuser",
                      "getuseraccess": "http://localhost/mycafe2api/public/api/user/getuseraccess",
                      "updateuseraccess": "http://localhost/mycafe2api/public/api/user/updateuseraccess",
                      "login": "http://localhost/mycafe2api/public/api/user/login",
                      "checksession": "http://localhost/mycafe2api/public/api/user/checksession",
                      "logout": "http://localhost/mycafe2api/public/api/user/logout",
                      "getuasession": "http://localhost/mycafe2api/public/api/user/getuasession",
                      "getusertable": "http://localhost/mycafe2api/public/api/user/getusertable",
                      "toggleusertable": "http://localhost/mycafe2api/public/api/user/toggleusertable",

                      "createobjuser": "http://localhost/mycafe2api/public/api/objuser/createobjuser",
                      "updateobjuser": "http://localhost/mycafe2api/public/api/objuser/updateobjuser",
                      "getobjusers": "http://localhost/mycafe2api/public/api/objuser/getobjusers",
                      "getobjuser": "http://localhost/mycafe2api/public/api/objuser/getobjuser",

                      "createmenutype": "http://localhost/mycafe2api/public/api/menutype/createmenutype",
                      "updatemenutype": "http://localhost/mycafe2api/public/api/menutype/updatemenutype",
                      "getmenutype": "http://localhost/mycafe2api/public/api/menutype/getmenutype",
                      "getmenutypes": "http://localhost/mycafe2api/public/api/menutype/getmenutypes",

                      "createcatagory": "http://localhost/mycafe2api/public/api/catagory/createcatagory",
                      "updatecatagory": "http://localhost/mycafe2api/public/api/catagory/updatecatagory",
                      "getcatgorys": "http://localhost/mycafe2api/public/api/catagory/getcatgorys",
                      "getcatgory": "http://localhost/mycafe2api/public/api/catagory/getcatgory",

                      "createmenu": "http://localhost/mycafe2api/public/api/menu/createmenu",
                      "updatemenu": "http://localhost/mycafe2api/public/api/menu/updatemenu",
                      "getmenus": "http://localhost/mycafe2api/public/api/menu/getmenus",
                      "getmenu": "http://localhost/mycafe2api/public/api/menu/getmenu",

                      "createtable": "http://localhost/mycafe2api/public/api/table/createtable",
                      "updatetable": "http://localhost/mycafe2api/public/api/table/updatetable",
                      "gettables": "http://localhost/mycafe2api/public/api/table/gettables",
                      "gettable": "http://localhost/mycafe2api/public/api/table/gettable",

                      "togglemenucat": "http://localhost/mycafe2api/public/api/menucatagory/togglemenucat",
                      "getmenucat": "http://localhost/mycafe2api/public/api/menucatagory/getmenucat",
                      "getmenucat_for_order": "http://localhost/mycafe2api/public/api/menucatagory/getmenucat_for_order",

                      "createorder": "http://localhost/mycafe2api/public/api/order/createorder",
                      "updateorder": "http://localhost/mycafe2api/public/api/order/updateorder",
                      "deleteorder": "http://localhost/mycafe2api/public/api/order/deleteorder",
                      "getorder": "http://localhost/mycafe2api/public/api/order/getorder",
                      "getorders": "http://localhost/mycafe2api/public/api/order/getorders",
                      "getstatuitems": "http://localhost/mycafe2api/public/api/order/getstatuitems",
                      "getorderlogs": "http://localhost/mycafe2api/public/api/order/getorderlogs",
                      "getordersforprocesschefe": "http://localhost/mycafe2api/public/api/order/getordersforprocesschefe",
                      "getordersforprocessbeve": "http://localhost/mycafe2api/public/api/order/getordersforprocessbeve",
                      "toggleproccessnext": "http://localhost/mycafe2api/public/api/order/toggleproccessnext",
                      "gettableforfinance": "http://localhost/mycafe2api/public/api/order/gettableforfinance",
                      "tofinance": "http://localhost/mycafe2api/public/api/order/tofinance",
                      "getordersforfinance": "http://localhost/mycafe2api/public/api/order/getordersforfinance",
                      "gettableforpayment": "http://localhost/mycafe2api/public/api/order/gettableforpayment",
                      "orderpayment": "http://localhost/mycafe2api/public/api/order/orderpayment",
                      "getpaymentdetbytable": "http://localhost/mycafe2api/public/api/order/getpaymentdetbytable",
                      "gettablebyuser": "http://localhost/mycafe2api/public/api/order/gettablebyuser",*/

            "createobjuser": "https://portal2.api.guansaplc.com/api/objuser/createobjuser",
            "updateobjuser": "https://portal2.api.guansaplc.com/api/objuser/updateobjuser",
            "getobjusers": "https://portal2.api.guansaplc.com/api/objuser/getobjusers",
            "getobjuser": "https://portal2.api.guansaplc.com/api/objuser/getobjuser",

            "siunitcreate": "https://portal2.api.guansaplc.com/api/siunit/create",
            "siunitupdate": "https://portal2.api.guansaplc.com/api/siunit/update",
            "siunitgetsiunits": "https://portal2.api.guansaplc.com/api/siunit/getsiunits",
            "siunitgetsiunit": "https://portal2.api.guansaplc.com/api/siunit/getsiunit",

            "departmentcreate": "https://portal2.api.guansaplc.com/api/department/create",
            "departmentupdate": "https://portal2.api.guansaplc.com/api/department/update",
            "departmentgetdepartments": "https://portal2.api.guansaplc.com/api/department/getdepartments",
            "departmentgetdepartment": "https://portal2.api.guansaplc.com/public/api/department/getdepartment",

            "employeecreate": "https://portal2.api.guansaplc.com/api/employee/create",
            "employeeupdate": "https://portal2.api.guansaplc.com/api/employee/update",
            "employeegetemployees": "https://portal2.api.guansaplc.com/api/employee/getemployees",
            "employeegetemployee": "https://portal2.api.guansaplc.com/api/employee/getemployee",

            "stockitemcreate": "https://portal2.api.guansaplc.com/api/stockitem/create",
            "stockitemupdate": "https://portal2.api.guansaplc.com/api/stockitem/update",
            "stockitemgetstockitems": "https://portal2.api.guansaplc.com/api/stockitem/getstockitems",
            "stockitemgetstockitem": "https://portal2.api.guansaplc.com/api/stockitem/getstockitem",

            "stockincreate": "https://portal2.api.guansaplc.com/api/stockin/create",
            "stockinupdate": "https://portal2.api.guansaplc.com/api/stockin/update",
            "stockingetstockins": "https://portal2.api.guansaplc.com/api/stockin/getstockins",
            "stockingetstockin": "https://portal2.api.guansaplc.com/api/stockin/getstockin",

            "stockoutcreate": "https://portal2.api.guansaplc.com/api/stockout/create",
            "stockoutupdate": "https://portal2.api.guansaplc.com/api/stockout/update",
            "stockoutgetstockouts": "https://portal2.api.guansaplc.com/api/stockout/getstockouts",
            "stockoutgetstockout": "https://portal2.api.guansaplc.com/api/stockout/getstockout",

            "stockwastagecreate": "https://portal2.api.guansaplc.com/api/stockwastage/create",
            "stockwastageupdate": "https://portal2.api.guansaplc.com/api/stockwastage/update",
            "getstockwastages": "https://portal2.api.guansaplc.com/api/stockwastage/getstockwastages",
            "getstockwastage": "https://portal2.api.guansaplc.com/api/stockwastage/getstockwastage",

            "reportgetstockin": "https://portal2.api.guansaplc.com/api/report/getstockin",
            "reportgetstockout": "https://portal2.api.guansaplc.com/api/report/getstockout",
            "reportgetstockwastage": "https://portal2.api.guansaplc.com/api/report/getstockwastage",
            "reportgetstockitem": "https://portal2.api.guansaplc.com/api/report/getstockitem",
            "rgetorders": "https://portal2.api.guansaplc.com/api/report/rgetorders",

            "createuser": "https://portal2.api.guansaplc.com/api/user/createuser",
            "updateuser": "https://portal2.api.guansaplc.com/api/user/updateuser",
            "changepwduser": "https://portal2.api.guansaplc.com/api/user/changepwduser",
            "getusers": "https://portal2.api.guansaplc.com/api/user/getusers",
            "getuser": "https://portal2.api.guansaplc.com/api/user/getuser",
            "getuseraccess": "https://portal2.api.guansaplc.com/api/user/getuseraccess",
            "updateuseraccess": "https://portal2.api.guansaplc.com/api/user/updateuseraccess",
            "login": "https://portal2.api.guansaplc.com/api/user/login",
            "checksession": "https://portal2.api.guansaplc.com/api/user/checksession",
            "logout": "https://portal2.api.guansaplc.com/api/user/logout",
            "getuasession": "https://portal2.api.guansaplc.com/api/user/getuasession",
            "getusertable": "https://portal2.api.guansaplc.com/api/user/getusertable",
            "toggleusertable": "https://portal2.api.guansaplc.com/api/user/toggleusertable",


            "createmenutype": "https://portal2.api.guansaplc.com/api/menutype/createmenutype",
            "updatemenutype": "https://portal2.api.guansaplc.com/api/menutype/updatemenutype",
            "getmenutype": "https://portal2.api.guansaplc.com/api/menutype/getmenutype",
            "getmenutypes": "https://portal2.api.guansaplc.com/api/menutype/getmenutypes",

            "createcatagory": "https://portal2.api.guansaplc.com/api/catagory/createcatagory",
            "updatecatagory": "https://portal2.api.guansaplc.com/api/catagory/updatecatagory",
            "getcatgorys": "https://portal2.api.guansaplc.com/api/catagory/getcatgorys",
            "getcatgory": "https://portal2.api.guansaplc.com/api/catagory/getcatgory",

            "createmenu": "https://portal2.api.guansaplc.com/api/menu/createmenu",
            "updatemenu": "https://portal2.api.guansaplc.com/api/menu/updatemenu",
            "getmenus": "https://portal2.api.guansaplc.com/api/menu/getmenus",
            "getmenu": "https://portal2.api.guansaplc.com/api/menu/getmenu",

            "createtable": "https://portal2.api.guansaplc.com/api/table/createtable",
            "updatetable": "https://portal2.api.guansaplc.com/api/table/updatetable",
            "gettables": "https://portal2.api.guansaplc.com/api/table/gettables",
            "gettable": "https://portal2.api.guansaplc.com/api/table/gettable",

            "togglemenucat": "https://portal2.api.guansaplc.com/api/menucatagory/togglemenucat",
            "getmenucat": "https://portal2.api.guansaplc.com/api/menucatagory/getmenucat",
            "getmenucat_for_order": "https://portal2.api.guansaplc.com/api/menucatagory/getmenucat_for_order",

            "createorder": "https://portal2.api.guansaplc.com/api/order/createorder",
            "updateorder": "https://portal2.api.guansaplc.com/api/order/updateorder",
            "deleteorder": "https://portal2.api.guansaplc.com/api/order/deleteorder",
            "getorder": "https://portal2.api.guansaplc.com/api/order/getorder",
            "getorders": "https://portal2.api.guansaplc.com/api/order/getorders",
            "getstatuitems": "https://portal2.api.guansaplc.com/api/order/getstatuitems",
            "getorderlogs": "https://portal2.api.guansaplc.com/api/order/getorderlogs",
            "getordersforprocesschefe": "https://portal2.api.guansaplc.com/api/order/getordersforprocesschefe",
            "getordersforprocessbeve": "https://portal2.api.guansaplc.com/api/order/getordersforprocessbeve",
            "toggleproccessnext": "https://portal2.api.guansaplc.com/api/order/toggleproccessnext",
            "gettableforfinance": "https://portal2.api.guansaplc.com/api/order/gettableforfinance",
            "tofinance": "https://portal2.api.guansaplc.com/api/order/tofinance",
            "getordersforfinance": "https://portal2.api.guansaplc.com/api/order/getordersforfinance",
            "gettableforpayment": "https://portal2.api.guansaplc.com/api/order/gettableforpayment",
            "orderpayment": "https://portal2.api.guansaplc.com/api/order/orderpayment",
            "getpaymentdetbytable": "https://portal2.api.guansaplc.com/api/order/getpaymentdetbytable",
            "gettablebyuser": "https://portal2.api.guansaplc.com/api/order/gettablebyuser",

            "urlwelcome": "/",
            "urlsiunit": "/siunit",
            "urldepartment": "/department",
            "urlemployee": "/employee",
            "urlstockitem": "/stockitem",
            "urlstockin": "/stockin",
            "urlstockout": "/stockout",
            "urlstockwastage": "/stockwastage",

            "urlmenutype": "/menutype",
            "urlcatagory": "/catagory",
            "urlmenulink": "/menu",
            "urlmenumatagory": "/menumatagory",

            "urlrstockin": "/rstockin",
            "urlrstockout": "/rstockout",
            "urlrstockwastage": "/rstockwastage",
            "urlrstockitem": "/rstockitem",
            "urlrorder": "/rorder",
            "urlrfinance": "/rfinance",
            "urluser": "/user",
            "urlobjuser": "/objuser",
            "useraccess1": "/useraccess1",
            "useraccess2": "/useraccess2",
            "urlusertable": "/usertable",


            "urlorder": "/order",
            "urltable": "/table",
            "urlchefe": "/chefe",
            "urlfinance": "/finance",
            "urlbeverage": "/beverage",

            "redirecttologin": "https://portal2.guansaplc.com/login",
            "redirect": "https://portal2.guansaplc.com",

            /*       "redirecttologin": "http://localhost:3000/login",
                    "redirect": "http://localhost:3000",*/
    }
export default myconfig;