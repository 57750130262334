import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';

let source = axios.CancelToken.source();

class Footer extends Component {

    constructor() {
        super();
    }
    componentDidMount() {
    }

    componentWillUnmount() {
    }
    render() {
        return (
          <>
              <footer className="main-footer text-sm">
                  <strong>Copyright &copy; 2014-2021 <a href="#">FY Software Development</a>.</strong>
                  All rights reserved.
                  <div className="float-right d-none d-sm-inline-block">
                      <b>Version</b> 1.0.0
                  </div>
              </footer>
          </>
        )
    }
}

export default Footer;
