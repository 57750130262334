import {Component} from 'react';
import axios from "axios"
import myconfig from "../myconfig";
import Swal from "sweetalert2";

let source = axios.CancelToken.source();

class Nav extends Component {
    constructor() {
        super();

        this.state = {
            matrix: [],
            martixload: false,
            stock: 'nav-item',
            stock1: 'nav-link',
            ssiunit: 'nav-link',
            sdepartment: 'nav-link',
            semployee: 'nav-link',
            stockitem: 'nav-link',
            stockin: 'nav-link',
            stockout: 'nav-link',
            stockwastage: 'nav-link',
            rstockitem: 'nav-link',

            menu: 'nav-item',
            menu1: 'nav-link',
            catagory: 'nav-link',
            menutype: 'nav-link',
            menulink: 'nav-link',
            menumatagory: 'nav-link',

            //single menus
            table: 'nav-link',
            order: 'nav-link',
            chefe: 'nav-link',
            beverage: 'nav-link',
            finance: 'nav-link',



            report: 'nav-item',
            report1: 'nav-link',
            rstockin: 'nav-link',
            rstockout: 'nav-link',
            rstockwastage: 'nav-link',
            rorder: 'nav-link',
            rfinance: 'nav-link',

            usermgt: 'nav-item',
            usermgt1: 'nav-link',
            user: 'nav-link',
            objuser: 'nav-link',
            useraccess1: 'nav-link',
            usertable: 'nav-link',

            dashboard: 'nav-item',
            dashboard1: 'nav-link',
            dvehiclestatus: 'nav-link',
            operation: 'nav-item',
            operation1: 'nav-link',
            oordermgt: 'nav-link',
            ostatus: 'nav-link',

            rfollowup: 'nav-link',
            rvehiclestatus: 'nav-link',
            rordersummury: 'nav-link',
            urlorder: "",
            urlstatus: "",
            urlfollowup: "",
            urlrvehiclestatus: "",
            urlrordersummury: "",
            urldasbord1: "",
        }
    }

    fetcheduseraccess = () => {
        let baseurl = myconfig.getuasession;
        let sid = window.localStorage.getItem("mycafe_sid")
        axios.post(baseurl, {
            sid: sid,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("mycafe_sid", null);
                window.localStorage.setItem("mycafe_username", null);
                window.localStorage.setItem("mycafe_name", null);
                var win = window.open(myconfig.redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data.datas)
                this.setState({
                    matrix: res.data.datas,
                    martixload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentDidMount() {
        this.fetcheduseraccess();
        if (this.props.type == "ssiunit") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    ssiunit: 'nav-link active',
                }
            );
        } else if (this.props.type == "sdepartment") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    sdepartment: 'nav-link active',
                }
            );
        } else if (this.props.type == "semployee") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    semployee: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockitem") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockitem: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockin") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockin: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockout") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockout: 'nav-link active',
                }
            );
        } else if (this.props.type == "stockwastage") {
            this.setState(
                {
                    stock: 'nav-item menu-open active',
                    stock1: 'nav-link active',
                    stockwastage: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "menutype") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    menutype: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "catagory") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    catagory: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "menulink") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    menulink: 'nav-link active',
                }
            );
        }
        else if (this.props.type == "menumatagory") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    menumatagory: 'nav-link active',
                }
            );
        }
         else if (this.props.type == "catbymenu") {
            this.setState(
                {
                    menu: 'nav-item menu-open active',
                    menu1: 'nav-link active',
                    catbymenu: 'nav-link active',
                }
            );
        } else if (this.props.type == "rstockin") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockin: 'nav-link active',
                }
            );
        } else if (this.props.type == "rstockout") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockout: 'nav-link active',
                }
            );
        } else if (this.props.type == "rstockwastage") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockwastage: 'nav-link active',
                }
            );
        }
         else if (this.props.type == "rstockitem") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rstockitem: 'nav-link active',
                }
            );
        }
         else if (this.props.type == "rorder") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rorder: 'nav-link active',
                }
            );
        }
         else if (this.props.type == "rfinance") {
            this.setState(
                {
                    report: 'nav-item menu-open active',
                    report1: 'nav-link active',
                    rfinance: 'nav-link active',
                }
            );
        }
         else if (this.props.type == "user") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    user: 'nav-link active',
                }
            );
        } else if (this.props.type == "objuser") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    objuser: 'nav-link active',
                }
            );
        }
         else if (this.props.type == "useraccess1") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    useraccess1: 'nav-link active',
                }
            );
        }
         else if (this.props.type == "usertable") {
            this.setState(
                {
                    usermgt: 'nav-item menu-open active',
                    usermgt1: 'nav-link active',
                    usertable: 'nav-link active',
                }
            );
        }

         else if (this.props.type == "table") {
            this.setState(
                {
                    table: 'nav-link active',
                }
            );
        } else if (this.props.type == "order") {
            this.setState(
                {
                    order: 'nav-link active',
                }
            );
        } else if (this.props.type == "chefe") {
            this.setState(
                {
                    chefe: 'nav-link active',
                }
            );
        } else if (this.props.type == "beverage") {
            this.setState(
                {
                    beverage: 'nav-link active',
                }
            );
        } else if (this.props.type == "finance") {
            this.setState(
                {
                    finance: 'nav-link active',
                }
            );
        }
    }

    logout = (event) => {
        event.preventDefault();
        let baseurl = myconfig.logout
        axios.post(baseurl, {
            'sid': window.localStorage.getItem("mycafe_sid"),
        }, {cancelToken: source.token}).then(res => {
        }).catch((e) => {
            console.log(e.message);
        });
        window.localStorage.setItem("mycafe_sid", null);
        window.localStorage.setItem("mycafe_username", null);
        window.localStorage.setItem("mycafe_name", null);
        var win = window.open(myconfig.redirecttologin, "_self");
    }
    displaymenus = () => {
        if (this.state.martixload) {
            let siunit = "";
            let department = "";
            let employee = "";
            let stockitem = "";
            let stockin = "";
            let stockout = "";

            let user = "";
            let objuser = "";
            let useraccess1 = "";
            let usertable = "";

            let rstockin = "";
            let rstockout = "";
            let rstockwastage = "";
            let rstockitem = "";
            let rorderitem = "";
            let rfinanceitem = "";

            let stockmenu = "";
            let usermenu = "";
            let reportmenu = "";
            let menumenu = "";


            let menutype = "";
            let menucat = "";
            let menus = "";
            let menu_menu_cat = "";


            let stockmgt = this.state.matrix.filter(value => {
                if (value.object_name == "siunit" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    siunit =
                        <a href={myconfig.urlsiunit} className={this.state.ssiunit}><i
                            className="far fa-circle nav-icon"/>
                            <p>SI Unit</p></a>
                    return value;
                }
                if (value.object_name == "department" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    department = <a href={myconfig.urldepartment} className={this.state.sdepartment}><i
                        className="far fa-circle nav-icon"/><p>Department</p></a>
                    return value;
                }
                if (value.object_name == "employee" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    employee = <a href={myconfig.urlemployee} className={this.state.semployee}><i
                        className="far fa-circle nav-icon"/><p>Employee</p></a>
                    return value;
                }
                if (value.object_name == "stockitem" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stockitem = <a href={myconfig.urlstockitem} className={this.state.stockitem}><i
                        className="far fa-circle nav-icon"/><p>Stock Item</p></a>
                    return value;
                }
                if (value.object_name == "stockin" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stockin = <a href={myconfig.urlstockin} className={this.state.stockin}><i
                        className="far fa-circle nav-icon"/><p>Stock IN</p></a>
                    return value;
                }
                if (value.object_name == "stockout" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    stockout = <a href={myconfig.urlstockout} className={this.state.stockout}><i
                        className="far fa-circle nav-icon"/><p>Stock Out</p></a>
                    return value;
                }
            })
            let usermgt = this.state.matrix.filter(value => {
                if (value.object_name == "user" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    user =
                        <a href={myconfig.urluser} className={this.state.user}><i className="far fa-circle nav-icon"/>
                            <p>User</p></a>
                    return value;
                }
                if (value.object_name == "objuser" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    objuser =
                        <a href={myconfig.urlobjuser} className={this.state.objuser}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Object</p></a>
                    return value;
                }
                if (value.object_name == "useraccess1" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    useraccess1 =
                        <a href={myconfig.useraccess1} className={this.state.useraccess1}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Access</p></a>
                    return value;
                }
                if (value.object_name == "usertable" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    usertable =
                        <a href={myconfig.urlusertable} className={this.state.usertable}><i
                            className="far fa-circle nav-icon"/>
                            <p>User Table</p></a>
                    return value;
                }
            })
            let reportmgt = this.state.matrix.filter(value => {
                if (value.object_name == "rstockin" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockin =
                        <a href={myconfig.urlrstockin} className={this.state.rstockin}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock In Report</p></a>
                    return value;
                }
                if (value.object_name == "rstockout" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockout =
                        <a href={myconfig.urlrstockout} className={this.state.rstockout}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Out Report</p></a>
                    return value;
                }
                if (value.object_name == "rstockwastage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockwastage =
                        <a href={myconfig.urlrstockwastage} className={this.state.rstockwastage}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Wastage Report</p></a>
                    return value;
                }
                if (value.object_name == "rstockitem" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rstockitem =
                        <a href={myconfig.urlrstockitem} className={this.state.rstockitem}><i
                            className="far fa-circle nav-icon"/>
                            <p>Stock Item Report</p></a>
                    return value;
                }
                if (value.object_name == "rorder" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rorderitem =
                        <a href={myconfig.urlrorder} className={this.state.rorder}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order Report</p></a>
                    return value;
                }
                if (value.object_name == "rfinance" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    rfinanceitem =
                        <a href={myconfig.urlrfinance} className={this.state.rfinance}><i
                            className="far fa-circle nav-icon"/>
                            <p>Order Finance</p></a>
                    return value;
                }
            })
            let menumgt = this.state.matrix.filter(value => {
                if (value.object_name == "menutype" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menutype =
                        <a href={myconfig.urlmenutype} className={this.state.menutype}><i
                            className="far fa-circle nav-icon"/>
                            <p>Menu Type</p></a>
                    return value;
                }
                if (value.object_name == "catagory" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menucat =
                        <a href={myconfig.urlcatagory} className={this.state.catagory}><i
                            className="far fa-circle nav-icon"/>
                            <p>Category</p></a>
                    return value;
                }
                if (value.object_name == "menu" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menus =
                        <a href={myconfig.urlmenulink} className={this.state.menulink}><i
                            className="far fa-circle nav-icon"/>
                            <p>Menu's</p></a>
                    return value;
                }
                if (value.object_name == "menumatagory" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    menu_menu_cat =
                        <a href={myconfig.urlmenumatagory} className={this.state.menumatagory}><i
                            className="far fa-circle nav-icon"/>
                            <p>Menu & Category</p></a>
                    return value;
                }
            })

            let singlemenu_table = this.state.matrix.filter(value => {
                if (value.object_name == "table" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if(singlemenu_table.length>0)
                singlemenu_table=<li className="nav-item">
                    <a href={myconfig.urltable} className={this.state.table}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Table</p>
                    </a>
                </li>
            else
                singlemenu_table=null;

            let singlemenu_order = this.state.matrix.filter(value => {
                if (value.object_name == "order" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if(singlemenu_order.length>0)
                singlemenu_order= <li className="nav-item">
                    <a href={myconfig.urlorder} className={this.state.order}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Order</p>
                    </a>
                </li>
            else
                singlemenu_order=null;

            let singlemenu_chefe = this.state.matrix.filter(value => {
                if (value.object_name == "chefe" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if(singlemenu_chefe.length>0)
                singlemenu_chefe=<li className="nav-item">
                    <a href={myconfig.urlchefe} className={this.state.chefe}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Chefe</p>
                    </a>
                </li>
            else
                singlemenu_chefe=null;

            let singlemenu_beverage = this.state.matrix.filter(value => {
                if (value.object_name == "beverage" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if(singlemenu_beverage.length>0)
                singlemenu_beverage= <li className="nav-item">
                    <a href={myconfig.urlbeverage} className={this.state.beverage}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Barman</p>
                    </a>
                </li>
            else
                singlemenu_beverage=null;
            let singlemenu_finance = this.state.matrix.filter(value => {
                if (value.object_name == "finance" &&
                    (value.create || value.update || value.read || value.delete)
                ) {
                    return value;
                }
            })
            if(singlemenu_finance.length>0)
                singlemenu_finance=   <li className="nav-item">
                    <a href={myconfig.urlfinance} className={this.state.finance}>
                        <i className="fas fa-circle nav-icon"></i>
                        <p>Finance</p>
                    </a>
                </li>
            else
                singlemenu_finance=null;


            if (stockmgt.length > 0) {
                stockmenu =
                    <li name="stock" onClick={(event => {
                        this.menuclick(event, this.state.stock)
                    })} className={this.state.stock}>
                        <a href="#" name="stock" onClick={(event => {
                            this.menuclick(event, this.state.stock)
                        })} className={this.state.stock1}>
                            <i className="nav-icon fas fa-box-open"/>
                            <p>
                                Stock Management
                                <i className="right fas fa-angle-left"/>
                            </p>
                        </a>
                        <ul className="nav nav-treeview">
                            <li className="nav-item">
                                {siunit}
                                {department}
                                {employee}
                                {stockitem}
                                {stockin}
                                {stockout}
                            </li>
                        </ul>
                    </li>
            }
            if (usermgt.length > 0) {
                usermenu = <li name="usermgt" onClick={(event => {
                    this.menuclick(event, this.state.usermgt)
                })} className={this.state.usermgt}>
                    <a name="usermgt" onClick={(event => {
                        this.menuclick(event, this.state.usermgt)
                    })} href="#" className={this.state.usermgt1}>
                        <i className="nav-icon fas fa-user"/>
                        <p>
                            User Managment
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {user}
                            {objuser}
                            {useraccess1}
                            {usertable}
                        </li>
                    </ul>
                </li>
            }
            if (reportmgt.length > 0) {
                reportmenu = <li name="report" onClick={(event => {
                    this.menuclick(event, this.state.report)
                })} className={this.state.report}>
                    <a name="report" onClick={(event => {
                        this.menuclick(event, this.state.report)
                    })} href="#" className={this.state.report1}>
                        <i className="nav-icon fas fa-bookmark"/>
                        <p>
                            Report
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {rstockin}
                            {rstockout}
                            {rstockwastage}
                            {rstockitem}
                            {rorderitem}
                            {rfinanceitem}
                        </li>
                    </ul>
                </li>
            }
            if (menumgt.length > 0) {
                menumenu = <li name="menu" onClick={(event => {
                    this.menuclick(event, this.state.menu)
                })} className={this.state.menu}>
                    <a name="menu" onClick={(event => {
                        this.menuclick(event, this.state.menu)
                    })} href="#" className={this.state.menu1}>
                        <i className="nav-icon fas fa-book-dead"/>
                        <p>
                            Menu
                            <i className="right fas fa-angle-left"/>
                        </p>
                    </a>
                    <ul className="nav nav-treeview">
                        <li className="nav-item">
                            {menutype}
                            {menucat}
                            {menus}
                            {menu_menu_cat}
                        </li>
                    </ul>
                </li>
            }
            return <>
                {stockmenu}
                {usermenu}
                {reportmenu}
                {menumenu}
                {singlemenu_table}
                {singlemenu_order}
                {singlemenu_chefe}
                {singlemenu_beverage}
                {singlemenu_finance}

            </>
        } else {
            return (<></>)
        }
    }
    menuclick = (event, value) => {
        var name = event.target.name;
        if (value == 'nav-item')
            value = 'nav-item menu-open active';
        else
            value = 'nav-item'
        this.setState({
            [name]: value,
        })
    }
    render() {
        return (
            <>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light text-sm">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i
                                className="fas fa-bars"/></a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="index3.html" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item d-none d-sm-inline-block">
                            <a href="#" className="nav-link">Contact</a>
                        </li>
                    </ul>
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="fullscreen" href="" onClick={this.logout}
                               role="button">
                                <i className="fas fa-sign-out-alt"/>
                            </a>
                        </li>
                    </ul>
                </nav>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <a href="#" className="brand-link text-sm">
                        <img src="dist/img/logo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-5"
                             style={{opacity: '.8'}}/>
                        <span className="brand-text font-weight-light">GM</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                            </div>
                            <div className="info">
                                <a href="#" className="d-block">Guansa Ma'ed</a>
                            </div>
                        </div>
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column text-sm" data-widget="treeview"
                                role="menu" data-accordion="false">
                                {this.displaymenus()}
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </>
        );
    }
}
export default Nav;
