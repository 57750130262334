import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
import Footer from "../Footer";
let source = axios.CancelToken.source();

class Beverage extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            objsload: false,
            id:-1,
        }
    }
    componentDidMount() {
        this.fetcheddata()
        setInterval(() => {
            this.fetcheddata(true)
        }, 10000)
    }
    fetcheddata = () => {
        this.setState({
            objs: [],
            objsload: false,
            id:-1,
        })
        let baseurl = myconfig.getordersforprocessbeve;
        axios.post(baseurl, {
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    submitcu = (event,id) => {
        event.preventDefault()
        let baseurl = myconfig.toggleproccessnext;
        axios.post(baseurl, {
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            let count = 0;
            retdata = this.state.objs.datas.map(value => {
                let status="Finished";
                if(value.status=="Queued")
                    status="Started";
                console.log(value)
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td><a href="" onClick={(event => {this.submitcu(event,value.id)})}>{status}</a></td>
                    <td>{value.name_am}</td>
                    <td>{value.name_en}</td>
                    <td>{value.price}</td>
                    <td>{value.type}</td>
                    <td>{value.table_name}</td>
                    <td>{value.order_by}</td>
                    <td>{value.quantity}</td>
                    <td>{value.remark}</td>
                    <td>{value.status}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    render() {
        return (
            <>
                <Nav type="beverage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Beverage Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Beverage</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Menu Amharic</th>
                                                    <th>Menu English</th>
                                                    <th>Price</th>
                                                    <th>Type</th>
                                                    <th>Table Name</th>
                                                    <th>Order By</th>
                                                    <th>Quantity</th>
                                                    <th>Remark</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer/>
            </>
        )
    }
}

export default Beverage;
