import {Component} from 'react';
import axios from "axios"
import { Modal,Button } from 'react-bootstrap';
import myconfig from "../../myconfig";
import Swal from "sweetalert2";

class StockitemSelect extends Component {

    constructor() {
        super();
        this.state = {
            siunit:'',
            name_en:'',
            name_am:'',
            type:'',
            selectshow: false,
            obj: [],
            filtered:[],
            objload: false,
            search:'',
        }
    }

    componentDidMount() {
        let name_en="-- Select Stock Item --";
        let name_am="-- Select Stock Item --";
        let siunit="--NA--";
        let type="--NA--";
        console.log(this.props)
        if(this.props.stock_item_obj!=null) {
            name_en = this.props.stock_item_obj.name_en;
            name_am = this.props.stock_item_obj.name_am;
            siunit = this.props.stock_item_obj.siunit;
            type = this.props.stock_item_obj.type;
        }
        this.setState({
            name_en: name_en,
            name_am: name_am,
            siunit: siunit,
            type: type,
        })
    }
    fetcheddata = () => {
        let baseurl = myconfig.stockitemgetstockitems;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
        }).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                /*  window.localStorage.setItem("fas_sid", null);
                  window.localStorage.setItem("fas_username", null);
                  window.localStorage.setItem("fas_name", null);
                  var win = window.open(redirecttologin, "_self");*/
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj: res.data,
                    filtered:res.data.datas,
                    objload:true,
                    selectshow: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.obj.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.siunit.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }

    showselect = (event) => {
        event.preventDefault()
        this.fetcheddata();

    }
    hideshow = () => {
        this.setState({
            selectshow: false,
        })
    }
    modalshow = () => {
        let tabledata=null;
        if(this.state.objload) {
            let count = 0;
            tabledata = this.state.filtered.map(value => {
                count=count+1
                return (<tr>
                    <td>{count}</td>
                    <td><a href="" onClick={(event => {this.submitselect(event,value)})}>Select</a></td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.type}</td>
                    <td>{value.siunit}</td>
                </tr>)
            })
        }
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.selectshow}
                onHide={this.hideshow}>
                <Modal.Header><h4>Stock Item </h4></Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-tools">
                                    <div className="input-group input-group-sm" style={{width: '150px'}}>
                                        <input type="text" name="search"
                                               className="form-control float-right" onChange={this.inputmgt}  placeholder="Search"/>
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-default">
                                                <i className="fas fa-search"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '400px'}}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                    <tr>
                                        <th width="5.5%">#</th>
                                        <th width="11.1%">Action</th>
                                        <th>Item Name (EN) </th>
                                        <th>Item Name (AMH) </th>
                                        <th>SI Unit </th>
                                        <th>Type</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tabledata}
                                    </tbody>
                                </table>
                            </div>
                            {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitselect = (event,value) => {
        event.preventDefault()
        this.setState({
            siunit:value.siunit,
            name_en:value.name_en,
            name_am:value.name_am,
            type:value.type,
            selectshow: false,
        }, () => {
            this.props.stockitem_selected(value.id)
        })
    }
    render() {
        return (
            <div className="card">
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12 px-3">
                            <div className="form-group">
                                <label>Item Name (En)</label>
                                <label onClick={this.showselect} className="form-control"  name="name_en">
                                    {this.state.name_en}
                                </label>
                            </div>
                        </div>
                        <div className="col-12 px-3">
                            <div className="form-group">
                                <label>Item Name (Amh)</label>
                                <label onClick={this.showselect} className="form-control"  name="name_en">
                                    {this.state.name_am}
                                </label>
                            </div>
                        </div>
                        <div className="col-12 px-3">
                            <div className="form-group">
                                <label>SI Unit</label>
                                <label  className="form-control"  name="name_en">
                                    {this.state.siunit}
                                </label>
                            </div>
                        </div>
                        <div className="col-12 px-3">
                            <div className="form-group">
                                <label>Type</label>
                                <label  className="form-control"  name="name_en">
                                    {this.state.type}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {this.modalshow()}
            </div>
        )
    }
}

export default StockitemSelect;
