import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Welcome from "./Componet/Welcome";
import SIUnit from "./Componet/SIUnit";
import Department from "./Componet/Department";
import Employee from "./Componet/Employee";
import Stockitem from "./Componet/Stockitem";
import StockIN from "./Componet/StockIN";
import StockOUT from "./Componet/StockOUT";
import StockWastage from "./Componet/StockWastage";
import CatByMenu from "./Componet/CatByMenu";
import RStockIN from "./Componet/Report/RStockIN";
import RStockOut from "./Componet/Report/RStockOut";
import RStockWastage from "./Componet/Report/RStockWastage";
import RStockItem from "./Componet/Report/RStockItem";
import User from "./Componet/User";
import ObjUser from "./Componet/ObjUser";
import UserAccess1 from "./Componet/UserAccess1";
import UserAccess2 from "./Componet/UserAccess2";
import Login from "./Componet/Login";
import MenuType from "./Componet/Menu/MenuType";
import MenuCatagory from "./Componet/Menu/MenuCatagory";
import Catagory from "./Componet/Menu/Category";
import Menu from "./Componet/Menu/Menu";
import Order from "./Componet/Operation/Order";
import Table from "./Componet/Operation/Table";
import Chefe from "./Componet/Operation/Chefe";
import Finance from "./Componet/Operation/Finance";
import Beverage from "./Componet/Operation/Beverage";
import UserTable from "./Componet/UserTable";
import ROrder from "./Componet/Report/ROrder";
import RFinance from "./Componet/Report/RFinance";

function App() {
    return (
        <>
            <BrowserRouter basename='/'>
                <Switch>
                    <Route path="/" exact component={Welcome}/>
                    <Route path="/siunit" exact component={SIUnit}/>
                    <Route path="/department" exact component={Department}/>
                    <Route path="/employee" exact component={Employee}/>
                    <Route path="/stockitem" exact component={Stockitem}/>
                    <Route path="/stockin" exact component={StockIN}/>
                    <Route path="/stockout" exact component={StockOUT}/>
                    <Route path="/stockwastage" exact component={StockWastage}/>
                    <Route path="/catbymenu" exact component={CatByMenu}/>
                    <Route path="/rstockin" exact component={RStockIN}/>
                    <Route path="/rstockout" exact component={RStockOut}/>
                    <Route path="/rstockwastage" exact component={RStockWastage}/>
                    <Route path="/rstockitem" exact component={RStockItem}/>
                    <Route path="/rorder" exact component={ROrder}/>
                    <Route path="/rfinance" exact component={RFinance}/>
                    <Route path="/user" exact component={User}/>
                    <Route path="/objuser" exact component={ObjUser}/>
                    <Route path="/useraccess1" exact component={UserAccess1}/>
                    <Route path="/useraccess2" exact component={UserAccess2}/>
                    <Route path="/usertable" exact component={UserTable}/>
                    <Route path="/login" exact component={Login}/>

                    <Route path="/menutype" exact component={MenuType}/>
                    <Route path="/catagory" exact component={Catagory}/>
                    <Route path="/menu" exact component={Menu}/>
                    <Route path="/menumatagory" exact component={MenuCatagory}/>
                    <Route path="/table" exact component={Table}/>
                    <Route path="/order" exact component={Order}/>
                    <Route path="/chefe" exact component={Chefe}/>
                    <Route path="/beverage" exact component={Beverage}/>
                    <Route path="/finance" exact component={Finance}/>
                </Switch>
            </BrowserRouter>
        </>
    );
}

export default App;
